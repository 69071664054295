<template>
  <div id="level-progress-container">
    <v-tooltip bottom nudge-top="6" color="primary" :disabled="tooltip === ''"
               :open-delay="50" :close-delay="100" eager>
      <template v-slot:activator="{on,attrs}">
        <div id="level-progress-level" class="d-flex justify-center align-center">{{ level }}</div>
        <div id="level-progress-progress" v-on="on" v-bind="attrs">
          <v-progress-linear rounded height="30" :value="max !== 0 ? 100 * exp / max : 100">
            <template v-slot:default="{}">
              <span id="level-progress-hover">{{ sum + exp }} / {{ sum + max }}</span>
            </template>
          </v-progress-linear>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'LevelProgress',
  props: {
    level: {type: Number, required: true},
    exp: {type: Number, required: true},
    max: {type: Number, required: true},
    sum: {type: Number, required: true},
    tooltip: {type: String, required: true}
  }
}
</script>

<style scoped>
#level-progress-container {
  height: 60px;
  margin: 5px;
  width: 250px;
}

#level-progress-level {
  background-image: url('/static/star.svg');
  color: #ffffff;
  font-size: 18px;
  height: 60px;
  padding-top: 6px;
  position: relative;
  width: 60px;
  z-index: 2;
}

#level-progress-progress {
  margin-left: 32px;
  margin-top: -45px;
  position: relative;
  width: 208px;
  z-index: 1;
}

#level-progress-hover {
  color: #ffffff;
  color: rgba(0, 0, 0, 0);
}

#level-progress-progress:hover #level-progress-hover {
  color: rgba(255, 255, 255, 1);
}
</style>
