<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$store.state.profile.title"
            :description="$t('common.level') + ' ' + $store.state.profile.level"/>

    <!-- General points -->
    <div class="d-flex flex-row flex-wrap justify-space-around align-center mt-4 mb-16">
      <LevelProgress :level="$store.state.profile.level" :sum="$store.state.profile.exp.prev"
                     :exp="$store.state.profile.exp.curr - $store.state.profile.exp.prev"
                     :max="$store.state.profile.exp.next - $store.state.profile.exp.prev"
                     :tooltip="''" style="transform: scale(1.2)"/>
    </div>

    <!-- Event points -->
    <table id="main-points-table">
      <tbody>
      <tr v-for="event in $events" :key="event">
        <td class="main-points-text">{{ $t('events.' + event) }}</td>
        <td style="width: 260px">
          <LevelProgress :level="$store.state.profile.events[event].level"
                         :sum="$store.state.profile.events[event].prevExp"
                         :exp="$store.state.profile.events[event].currExp - $store.state.profile.events[event].prevExp"
                         :max="$store.state.profile.events[event].nextExp - $store.state.profile.events[event].prevExp"
                         :tooltip="getTooltip($store.state.profile.events[event].nextScore, $store.state.profile.events[event].nextTime, event)"/>
        </td>
        <td :style="{'visibility': $store.state.profile.events[event].time > 0 ? 'visible' : 'hidden'}"
            style="cursor: pointer" @click="openGame($store.state.profile.events[event].gid, event)">
          <v-icon large color="primary">mdi-medal-outline</v-icon>
          <span class="main-points-record">
            {{ parseRecord($store.state.profile.events[event].score, $store.state.profile.events[event].time) }}
          </span>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Footer -->
    <Footer :help="true" :on-help="showHelp"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';
import LevelProgress from '../components/LevelProgress';
import Footer from '../components/Footer';
import config from '../../../data/config.json';

export default {
  name: 'MainPoints',
  components: {Header, LevelProgress, Footer},
  methods: {
    getTooltip(nextScore, nextTime, event) {
      if (nextScore >= 0) {
        return this.$t('help.for-next-level') + ' ' + nextScore;
      } else if (nextTime >= 0) {
        let m = Math.floor(nextTime / 6000);
        let s = Math.floor((nextTime - m * 6000) / 100);
        let ms = nextTime - m * 6000 - s * 100;
        if (m === 0) return this.$t('help.for-next-level') + ' ' + config.events[event].default.number + ' ' + this.$t('common.in') + ' ' + s + '.' + ms.toString().padStart(2, '0');
        else return this.$t('help.for-next-level') + ' ' + config.events[event].default.number + ' ' + this.$t('common.in') + ' ' + m + ' ' + s.toString().padStart(2, '0') + '.' + ms.toString().padStart(2, '0');
      } else return '';
    },
    openGame(gid, event) {
      if (gid === '') return;
      this.$api.getGame(gid).then(() => {
        this.$store.commit('setLocation', '/points');
        this.$router.push('/play/training/' + event);
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    parseRecord(score, time) {
      let m = Math.floor(time / 6000);
      let s = Math.floor(time / 100) - m * 60;
      let ms = time - m * 6000 - s * 100;
      let res;
      if (m === 0) res = s + '.' + ms.toString().padStart(2, '0');
      else res = m + ':' + s.toString().padStart(2, '0') + '.' + ms.toString().padStart(2, '0');
      return score + ' ' + this.$t('common.in') + ' ' + res;
    },
    showHelp() {
      let sections = [{
        title: this.$t('help.points-levels-title'),
        icon: 'mdi-medal-outline',
        content: this.$t('help.points-levels-description')
      }, {
        title: this.$t('help.points-general-title'),
        icon: 'mdi-book-account-outline',
        content: this.$t('help.points-general-description')
      }];
      this.$store.commit('showAdvancedDialog', {title: '', sections})
    }
  }
}
</script>

<style scoped>
#main-points-table {
  margin: 24px;
  width: calc(100% - 24px);
}

.main-points-text {
  color: #336799;
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
}

.main-points-record {
  color: #336799;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
}

@media (max-width: 800px) {
  #main-points-table tr {
    display: inline-block;
    margin-bottom: 48px;
  }

  #main-points-table td {
    display: inline-block;
    padding: 4px;
    width: 250px;
  }
}
</style>
